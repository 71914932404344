.payment-method-card{
    border: 1px solid #f0f0f0;
    padding:12px;
    border-radius : 6px;
}

.payment-method-container.selected > .payment-method-card{
    background: #efeffe;
    border-color: #6765FE;
}

.sale-cart-item:hover .mask{
	opacity: 0.8;
   	background-color:rgba(0,0,0,0.1);
}


.sale-cart-item .mask{
	cursor: pointer;
	opacity: 1;
	border-radius: 4px;
	overflow: visible;
	box-sizing:border-box;
	transition: all 0.4s ease-in-out;
}

/* card sale */
.ant-ribbon-wrapper{
	height: 100%;
}

@media print {
	.printable-area {
	  width: 80mm;
	  height: 100%;
	}
  }

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.intro-wrapper{
    padding: 40px 50px;
}

@media only screen and (max-width: 767px){
    .intro-wrapper {
        width: 0;
        padding: 50px 0;
    }

    .login-content-wrapper{
        padding: 50px 20px;
        background : linear-gradient(160deg, #f7fafc 20%, #fff 40%) !important;
    }
}
.content{
    overflow-x: hidden;
    margin-bottom: 40px;
}

.main-content{
    min-height: 92vh;
    background: #f7fafc;
}

.main-menu.ant-menu{
    background: transparent;
    padding: 0 14px;
}

.main-menu.ant-menu .ant-menu-item{
    border-radius: 8px;
    display: flex;
    align-items : center;
}

.main-menu.ant-menu .ant-menu-item.ant-menu-item-selected{
    background-color: #575759;
}

.sub-menu-select{
    cursor: pointer;
}

.sub-menu-select .right-arrow{
    color: #8c8c8c;
    /* transform: translateX(-100%); */
    transition: all 0.5s;
}

.sub-menu-select:hover .right-arrow{
    color: #000;
    transform: translateX(30%);
}

.input_wrapper > input,
.input_wrapper > .ant-input-affix-wrapper{
    border-radius: 8px;
    /* border: none; */
    background: #fff;
}

.button-shadow.secondary{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px;
}

.custom-modal .ant-modal-header{
    border-radius: 6px 6px 0 0;   
}

.custom-modal .ant-modal-content{
    border-radius: 6px;
}

/* table */
.hub-table .ant-table{
    /* border : 1px solid #f0f0f0; */
    border-radius: 8px;
}

.hub-table-list .ant-card-body{
    padding: 24px 0;
}

.hub-table-list .ant-table-pagination.ant-pagination{
    margin: 16px;
}

.hub-table .ant-table-thead > tr > th{
    background: #fff;
    border-bottom : 1px solid #f5f5f5;
    color : #434343;
}

.hub-table .ant-table-tbody > tr > td{
    /* border-bottom: none; */
    border-bottom : 1px solid #f5f5f5;
    color: #595959;
}

.hub-table .ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius : 8px;
    padding: 0 40px;
}

.hub-table .ant-table-tbody > tr > td:first-child{
    padding: 0 40px;
}

.hub-table .ant-table-container table > thead > tr:first-child th:last-child{
    border-top-right-radius : 8px;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    display: none;
}


.hub-table .action-td{
    text-align: right;
}

.hub-table .ant-table-thead > tr > th.col-border,
.hub-table .ant-table-tbody > tr > td.col-border{
    border-left: 1px solid #d9d9d9;
}

/* button */
.mini-button.mini-style-text{
    font-weight: 500;
    color: #6765FE;
    cursor: pointer;
    /* box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px; */
}

/* input item */
.border-input,
.border-input .ant-select-selector{
    border-radius: 6px !important;
}


textarea{resize: none}